import React from 'react'
import styles from './index.module.scss'
import * as actions from '../../actions'

function ModalViewNumber({ dispatch, user }) {
  return (
    <div className={styles.ModalViewNumber}>
      <div
        className={styles.modalBg}
        onClick={() => {
          dispatch(actions.isModalMobilePhone(null))
        }}
      />
      <div className={styles.modalContainer}>
        <div className={styles.modalInner}>
          <div
            className={styles.closeContainer}
            onClick={() => {
              dispatch(actions.isModalMobilePhone(null))
            }}
          >
            <i className={styles.closeIcon}></i>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalViewNumber
